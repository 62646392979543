import React from "react";

import Anchor from "../elements/Anchor";
import { CPoint } from "../../helpers/Icons";

const CtaChargespot = ({ className, data }) => {
  return (
    <Anchor
      href={data.chargespotLink.url}
      target={data.chargespotLink.target}
      title={data.chargespotLabel}
      // eslint-disable-next-line prettier/prettier
      className={`cta-link cta-link--chargespot link-unstyled ${className || ""}`}
    >
      <CPoint />
      <span>{data.chargespotLabel}</span>
    </Anchor>
  );
};

export default CtaChargespot;
