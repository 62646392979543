import React from "react";
import { Helmet } from "react-helmet";

import { imageObject } from "./seoHelpers";

const SchemaWebsite = ({ title, description, image, url, siteUrl }) => {
  return (
    <Helmet>
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org/",
          "@type": "WebSite",
          "url": "${url}",
          "name": "${title}",
          "description": "${description}",
          "image": ${imageObject(image)},
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "${siteUrl}"
          }
        }
      `}</script>
    </Helmet>
  );
};

export default SchemaWebsite;
