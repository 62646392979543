import React from "react";
import { Helmet } from "react-helmet";

import { imageObject } from "./seoHelpers";

const SchemaNewsArticle = ({ headline, image, datePublished, author }) => {
  let published = "";
  if (datePublished) {
    const dateParts = datePublished.split("/");
    published = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);

    if (published instanceof Date && published.toString() !== "Invalid Date") {
      published = published.toISOString();
    } else {
      published = datePublished;
    }
  }

  return (
    <Helmet>
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "NewsArticle",
          "headline": "${headline}",
          "image": [${imageObject(image)}],
          "datePublished": "${published}",
          "author": [${author}]
        }
      `}</script>
    </Helmet>
  );
};

export default SchemaNewsArticle;
