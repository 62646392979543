import React from "react";

import Anchor from "../elements/Anchor";
import { CPin } from "../../helpers/Icons";

const CtaCharge = ({ className, data }) => {
  return (
    <Anchor
      href={data.chargeLink.url}
      target={data.chargeLink.target}
      className={`cta-link cta-link--charge link-unstyled ${className || ""}`}
      title={data.chargeLabel}
    >
      <CPin />
      <span>{data.chargeLabel}</span>
    </Anchor>
  );
};

export default CtaCharge;
