import React from "react";

import { Facebook, Instagram, LinkedIn, Youtube } from "../../helpers/Icons";

import "../../styles/components/elements/social-icons.scss";

const SocialIcons = ({ className }) => (
  <ul className={`site-social list-inline ${className || ""}`}>
    <li className="list-inline-item">
      <a
        href="https://www.facebook.com/protergia.gr/"
        title="Follow us on Facebook"
        rel="external noreferrer noopener nofollow"
        target="_blank"
        className="site-social__account p-1"
      >
        <Facebook />
        <span className="visually-hidden">Facebook</span>
      </a>
    </li>
    <li className="list-inline-item">
      <a
        href="https://www.instagram.com/protergia.gr/"
        title="Follow us on Instagram"
        rel="external noreferrer noopener nofollow"
        target="_blank"
        className="site-social__account p-1"
      >
        <Instagram />
        <span className="visually-hidden">Instagram</span>
      </a>
    </li>
    <li className="list-inline-item">
      <a
        href="https://www.youtube.com/channel/UCiW1uBiWHVCgL8hCUzBgpuQ"
        title="Follow us on Youtube"
        rel="external noreferrer noopener nofollow"
        target="_blank"
        className="site-social__account p-1"
      >
        <Youtube />
        <span className="visually-hidden">Youtube</span>
      </a>
    </li>
    <li className="list-inline-item">
      <a
        href="https://www.linkedin.com/company/6646293"
        title="Follow us on LinkedIn"
        rel="external noreferrer noopener nofollow"
        target="_blank"
        className="site-social__account p-1"
      >
        <LinkedIn />
        <span className="visually-hidden">LinkedIn</span>
      </a>
    </li>
  </ul>
);

export default SocialIcons;
