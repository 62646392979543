import * as React from "react";

import { Dropdown } from "react-bootstrap";

import { linkResolver } from "../../utils/prismic-linkResolver";
import { ArrowDownThick, CheckMark } from "../../helpers/Icons";

const LanguageSwitcher = ({ activeDocMeta }) => {
  if (!activeDocMeta) return null;

  // Hide switcher when we don't have other languages
  if (activeDocMeta.alternateLanguages?.length <= 0) return null;

  const currentLang = activeDocMeta.lang;
  const currentLangOption = currentLang.slice(0, 2).toUpperCase();

  const alternateLangOptions = activeDocMeta.alternateLanguages.map(
    (altLang) => (
      <Dropdown.Item
        href={linkResolver(altLang)}
        key={`alt-lang-${altLang}`}
        className="py-2"
      >
        {altLang.lang.slice(0, 2).toUpperCase()}
      </Dropdown.Item>
    )
  );

  return (
    <Dropdown className="site-language-switcher">
      <Dropdown.Toggle variant="none" id="lang.dropdown">
        <span className="me-1">{currentLangOption}</span>
        <ArrowDownThick />
      </Dropdown.Toggle>
      <Dropdown.Menu variant="dark" renderOnMount className="p-0">
        <Dropdown.Item disabled className="d-flex align-items-baseline py-2">
          <span className="me-1">{currentLangOption}</span>
          <CheckMark />
        </Dropdown.Item>
        {alternateLangOptions}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageSwitcher;
