import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import { Col, Container, Row } from "react-bootstrap";

import Anchor from "../elements/Anchor";
import AppsIcons from "../elements/AppsIcons";
import SocialIcons from "../elements/SocialIcons";
import { Logo } from "../../helpers/Icons";
import { siteLang as defaultLanguage } from "../../../config/site-config";

import "../../styles/components/blocks/footer.scss";

const Footer = ({ settings }) => {
  const {
    footer_menu: footerMenu,
    footer_menu_2: footerMenu2,
    footer_image: image,
    footer_image_mobile: imageMobile,
    wv_name: wvName,
    wv_title: wvTitle,
    wv_link: wvLink,
  } = settings.data;
  const homepageUrl =
    settings.lang === defaultLanguage
      ? "/"
      : `/${settings.lang.split("-")[0]}/`;

  const menu = footerMenu.map((item) => (
    <Anchor
      key={item.label}
      href={item.link.url}
      target={item.link.target}
      title={item.label}
      className="link-unstyled"
    >
      {item.label}
    </Anchor>
  ));

  const menu2 = footerMenu2.map((item) => (
    <Anchor
      key={item.label}
      href={item.link.url}
      target={item.link.target}
      title={item.label}
      className="link-unstyled"
    >
      {item.label}
    </Anchor>
  ));

  return (
    <footer className="site-footer">
      <GatsbyImage
        image={image.gatsbyImageData}
        className="site-footer__top-image d-none d-md-block mb-3 pe-none"
        alt={image.alt || ""}
      />
      <GatsbyImage
        image={imageMobile.gatsbyImageData}
        className="site-footer__top-image d-md-none mb-3 pe-none"
        alt={imageMobile.alt || ""}
      />
      <Container fluid>
        <Row>
          <Col xxl={{ span: 3, offset: 1 }}>
            <Anchor
              href={homepageUrl}
              className="site-footer__logo site-footer-mb"
            >
              <Logo />
            </Anchor>
            <AppsIcons
              settings={settings}
              size="small"
              className="site-footer__apps site-footer-mb"
            />
          </Col>
          <Col md={6} xxl={2}>
            <nav className="site-footer__nav">{menu}</nav>
          </Col>
          <Col md={6} xxl={2}>
            <nav className="site-footer__nav site-footer-mb">{menu2}</nav>
          </Col>
          <Col xxl={3} className="d-grid justify-content-between">
            <p className="site-footer__copyright">
              © {new Date().getFullYear()}{" "}
              <a
                href={wvLink.url}
                title={wvTitle}
                rel="noreferrer"
                target="_blank"
                style={{ color: "#FF9800" }}
              >
                {wvName}
              </a>
              <br />
              ALL RIGHTS RESERVED
              <br />
              <a
                href="https://think-plus.gr/"
                title="Think Plus | Creative and software agency"
                rel="noreferrer"
                target="_blank"
                className="small text-muted"
              >
                DEVELOPED BY THINK PLUS
              </a>
            </p>
            <SocialIcons className="site-footer-mb" />
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
