import React from "react";
import { Helmet } from "react-helmet";

import { imageObject } from "./seoHelpers";

const SchemaProduct = ({
  name,
  description,
  model,
  image,
  brand,
  url,
  price,
}) => {
  return (
    <Helmet>
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "Product",
          "name": "${name}",
          "description": "${description}",
          "model": "${model}",
          "brand": {
            "@type": "Brand",
            "name": "${brand}"
          },
          "image": ${imageObject(image)},
          "offers": {
            "@type": "Offer",
            "url": "${url}",
            "availability": "https://schema.org/InStock",
            "price": "${price}",
            "priceCurrency": "EUR"
          }
        }
      `}</script>
    </Helmet>
  );
};

export default SchemaProduct;
