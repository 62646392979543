import React from "react";
import { Helmet } from "react-helmet";

import { getOrganization } from "./seoHelpers";

const SchemaOrganization = ({
  name,
  alternateName,
  url,
  logo,
  contactPoint,
  street,
  locality,
  postal,
  phone,
  email,
}) => {
  return (
    <Helmet>
      <script type="application/ld+json">{`${getOrganization({
        name,
        alternateName,
        url,
        logo,
        contactPoint,
        street,
        locality,
        postal,
        phone,
        email,
      })}`}</script>
    </Helmet>
  );
};

export default SchemaOrganization;
