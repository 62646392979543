import {
  defaultImage as cDefaultImage,
  defaultImageWidth,
  defaultImageHeight,
  defaultImageAlt,
} from "../../../config/site-config";

export const defaultImage = {
  url: cDefaultImage,
  alt: defaultImageAlt,
  dimensions: {
    height: defaultImageHeight,
    width: defaultImageWidth,
  },
};

export const imageObject = (im) => {
  const image = im?.url ? im : defaultImage;

  return `{
    "@type": "ImageObject",
    "url": "${image.url}",
    ${image.alt ? `"description": "${image.alt}",` : ``}
    "width": "${image.dimensions.width}",
    "height": "${image.dimensions.height}"
  }`;
};

export const contactPoint = (phones, emails) => {
  if (!phones && !emails) return "";

  const phonePoints =
    phones?.map(
      (phone) => `{
      "@type" : "ContactPoint",
      "telephone" : "${phone.number}",
      "contactType" : "${phone.label}"
    }`
    ) || [];

  const emailPoints =
    emails?.map(
      (email) => `{
      "@type" : "ContactPoint",
      "email" : "${email.address}",
      "contactType" : "${email.label}"
    }`
    ) || [];

  const points = [...phonePoints, ...emailPoints];

  return `[
      ${points.join(",")}
    ]`;
};

export const getOrganization = ({
  name,
  alternateName,
  url,
  logo,
  contactPoint: contactPoints,
  street,
  locality,
  postal,
  phone,
  email,
}) => `{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "${name}",
  "alternateName": "${alternateName}",
  "url": "${url}",
  "logo": ${logo},
  "image": ${logo},
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "${street}",
    "addressLocality": "${locality}",
    "postalCode": "${postal}"
  },
  "telephone": "${phone}",
  "email": "${email}"
  ${contactPoints ? `,"contactPoint": ${contactPoints}` : ""}
}`;
