import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import Header from "./Header";
import Footer from "./Footer";

import { shortTitle } from "../../../config/site-config";

import "../../styles/main.scss";

const Layout = ({ children, settings, activeDocMeta, headerClass }) => {
  if (!settings) return null;
  const { code_for_head: codeHead, code_for_body: codeBody } = settings.data;

  useEffect(() => {
    if (codeHead.text !== "") {
      document.head.append(
        document.createRange().createContextualFragment(codeHead.text)
      );
    }
    if (codeBody.text !== "") {
      document.body.append(
        document.createRange().createContextualFragment(codeBody.text)
      );
    }
  }, [codeBody.text, codeHead.text]);

  return (
    <>
      <Helmet defaultTitle={shortTitle}>
        <html lang={settings.lang} />
      </Helmet>

      <div className="site" data-type={activeDocMeta.type}>
        <Header
          settings={settings}
          activeDocMeta={activeDocMeta}
          headerClass={headerClass}
        />

        <main className="site-main">{children}</main>

        <Footer settings={settings} />
      </div>
    </>
  );
};

export default Layout;
