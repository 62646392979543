import React from "react";

import { Accordion } from "react-bootstrap";

import Anchor from "./Anchor";

const MenuItemDrawer = ({ menu }) => {
  const childs = menu.items;
  const hasChilds =
    childs?.length > 0 && childs[0].sub_label && childs[0].sub_link.url;
  const hasLink = menu.primary.link.url;

  const menuBody = (
    <Anchor
      href={menu.primary.link.url}
      target={menu.primary.link.target}
      className="nav-link link-unstyled"
    >
      {menu.primary.label}
    </Anchor>
  );

  return (
    <div className="main-menu__item">
      {hasChilds ? (
        <Accordion flush>
          <Accordion.Item eventKey="0">
            {hasLink ? (
              <span className="d-flex">
                {menuBody}
                <Accordion.Button />
              </span>
            ) : (
              <Accordion.Header className="nav-link">
                {menu.primary.label}
              </Accordion.Header>
            )}
            <Accordion.Body className="ps-2 pt-1 pb-3">
              {childs.map((child) => (
                <span
                  className="ps-4 nav-link link-unstyled"
                  key={child.sub_label}
                >
                  <Anchor
                    key={child.sub_label}
                    href={child.sub_link.url}
                    target={child.sub_link.target}
                    className="link-unstyled"
                  >
                    {child.sub_label}
                  </Anchor>
                </span>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ) : (
        menuBody
      )}
    </div>
  );
};

export default MenuItemDrawer;
