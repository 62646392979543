import React from "react";

import { Dropdown } from "react-bootstrap";

import Anchor from "./Anchor";
import { ArrowDownThick } from "../../helpers/Icons";

const MenuItem = ({ menu }) => {
  const childs = menu.items;
  const hasChilds =
    childs?.length > 0 && childs[0].sub_label && childs[0].sub_link.url;
  const hasLink = menu.primary.link.url;

  
  const menuBody = (
    <Anchor
      href={menu.primary.link.url}
      target={menu.primary.link.target}
      className="nav-link link-unstyled"
    >
      {menu.primary.label}
    </Anchor>
  );

  const menuItems = childs.map((child) => (
    <Dropdown.Item
      as={Anchor}
      key={child.sub_label}
      href={child.sub_link.url}
      target={child.sub_link.target}
      className="link-unstyled"
    >
      {child.sub_label}
    </Dropdown.Item>
  ));

  return (
    <div className="main-menu__item" key={menu.primary.label}>
      {hasChilds ? (
        <Dropdown id={`mainMenu.dropdown-${menu.primary.label}`}>
          {hasLink && menuBody}
          <Dropdown.Toggle variant="link" className="link-unstyled">
            {!hasLink && <span className="me-2">{menu.primary.label}</span>}
            <ArrowDownThick />
          </Dropdown.Toggle>
          <Dropdown.Menu
            align={hasLink ? "end" : "start"}
            flip
            renderOnMount
            variant="dark"
          >
            {menuItems}
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        menuBody
      )}
    </div>
  );
};

export default MenuItem;
