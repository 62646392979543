import React from "react";

import { AppStore, GooglePlay } from "../../helpers/Icons";

import "../../styles/components/elements/apps-icons.scss";

// Size values: "full", "small", "large"
const AppsIcons = ({ settings, size = "full", className }) => {
  const {
    apple_app_label: appleAppLabel,
    apple_app_link: appleAppLink,
    google_app_label: googleAppLabel,
    google_app_link: googleAppLink,
  } = settings.data;

  return (
    <div className={`site-apps site-apps--${size} ${className || ""}`}>
      <a
        href={googleAppLink.url}
        title={googleAppLabel}
        rel="external noreferrer noopener nofollow"
        target="_blank"
      >
        <GooglePlay title={googleAppLabel} />
      </a>
      <a
        href={appleAppLink.url}
        title={appleAppLabel}
        rel="external noreferrer noopener nofollow"
        target="_blank"
      >
        <AppStore title={appleAppLabel} />
      </a>
    </div>
  );
};

export default AppsIcons;
